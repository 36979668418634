<template>
  <div class="main-lists mb-3">
    <h1 class="header">{{ translate(cardlist.title) }}</h1>
    <vue-horizontal class="horizontal">
      <Card v-for="(card, index) in cards" :key="index" :card="card" />
    </vue-horizontal>
  </div>
</template>
<script>
import VueHorizontal from "vue-horizontal";
import { translateSetting } from "@/utils/translated-setting.util";
import Card from "@/components/landing/Card.vue";

export default {
  props: ["cardlist"],
  data() {
    return {
      cards: this.cardlist.blocks,
    }
  },
  components: {
    VueHorizontal,
    Card,
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    translate(value) {
      return translateSetting(value, this.locale);
    },
  },
};
</script>
<!-- Parent CSS (Container) -->
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.main-lists {
  margin-bottom: 2rem !important;
}

.content {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  z-index: -1;
}

.aspect-ratio {
  padding-top: 60%;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000010;
  justify-content: center;
}

.title-group {
  z-index: 1;
  padding: 15px;
  position: absolute;
  top: 55px;
  height: 75%;
  transition: top 1s;

  .overlay-title {
    font-size: 1.4em !important;
  }

  .overlay-description {
    margin-top: 5px;
    font-size: 1.2em;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
  }
}

.overlay-block {
  // z-index: 1;
  padding: 15px;
  position: absolute;
  opacity: 0.8;
  bottom: 0;
  left: 0;
  height: 25%;
  width: 100%;
  background-color: $color-primary;
  transition: height 0.7s;
  .time-date {
    position: absolute;
    bottom: 12px;
    // line-height: 20px;
    .date {
      margin-left: 10px;
      font-size: 16px;
    }
    .time {
      font-size: 14px;
    }
  }
}

.item:hover {
  .title-group {
    top: 8px;
    .overlay-description {
      visibility: visible;
      opacity: 1;
    }
  }
  .overlay-block {
    height: 100%;
  }
}

.overlay > * {
  color: white;
}
</style>

<!-- Parent CSS (Container) -->
<style scoped>
.header {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
</style>

<style scoped>
.horizontal {
  --count: 1;
  --gap: 16px;
  --margin: 24px;
}

@media (min-width: 640px) {
  .horizontal {
    --count: 2;
  }
}

@media (min-width: 768px) {
  .horizontal {
    --count: 3;
    --margin: 0;
  }
}

@media (min-width: 1024px) {
  .horizontal {
    --count: 4;
  }
}

@media (min-width: 1280px) {
  .horizontal {
    --gap: 24px;
    --count: 6;
  }
}
</style>
<!-- Responsive Breakpoints -->
<style scoped>
.horizontal {
  --count: 1;
  --gap: 16px;
  --margin: 24px;
}

@media (min-width: 640px) {
  .horizontal {
    --count: 2;
  }
}

@media (min-width: 768px) {
  .horizontal {
    --count: 2;
    --margin: 0;
  }
}

@media (min-width: 1024px) {
  .horizontal {
    --count: 3;
  }
}

@media (min-width: 1280px) {
  .horizontal {
    --gap: 24px;
  }
}
</style>

<!--
## Responsive Logic
The margin removes the padding from the parent container and add it into vue-horizontal.
If the gap is less than margin, this causes overflow to show and peeks into the next content for better UX.
You can replace this section entirely for basic responsive CSS logic if you don't want this "peeking" experience
for the mobile web.
Note that this responsive logic is hyper sensitive to your design choices, it's not a one size fit all solution.
var() has only 95% cross browser compatibility, you should convert it to fixed values.

There are 2 set of logic:
0-768 for peeking optimized for touch scrolling.
>768 for navigation via buttons for desktop/laptop users.
-->
<style lang="scss" scoped>
@media (max-width: 767.98px) {
  .item {
    width: 250px;
    padding-left: var(--margin);
  }
  .title-group {
    // position: absolute;
    top: 0px;
    height: 75%;
    .overlay-title {
      font-size: 25px !important;
    }
  }
  .overlay-block {
    .time-date {
      bottom: 12px;
    }
  }

  .item:hover {
    .title-group {
      top: 7px;
      .overlay-description {
        visibility: hidden;
        opacity: 0;
      }
    }
    .overlay-block {
      height: 25%;
    }
  }

  .item:first-child {
    // width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) - (var(--gap) / 2));
    padding-left: var(--margin);
  }

  // .item:last-child {
  //   //  width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) - (var(--gap) / 2));
  //   padding-right: var(--margin);
  // }

  // .item:only-child {
  //   //  width: calc((100% - (var(--margin) * 2) + var(--gap)) / var(--count) + var(--margin) * 2 - var(--gap));
  // }

  // .horizontal {
  //   margin: 0 calc(var(--margin) * -1);
  // }

  .horizontal >>> .v-hl-container {
    scroll-padding: 0 calc(var(--margin) - (var(--gap) / 2));
  }
}

@media (min-width: 768px) {
  .item {
    width: 300px;
    margin-right: var(--gap);
  }
}

@media (max-width: 768px) {
  .horizontal >>> .v-hl-btn svg {
    display: none;
  }
}
</style>
