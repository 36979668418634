<template>
  <iframe 
    :src='`https://go.arena.im/embed/chat/${code}`' 
    ></iframe>
</template>
<script>
export default {
  name: "vimeo",
  props: ["currentUser", "settings", "code"],
};
</script>
<style lang="scss">
.chat {
  height: 75%;
  width: 100%;
  min-height: 536.364px;
//   min-width: 265.455px;
}
</style>