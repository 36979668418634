<template>
  <div class="col-md-12 col-lg-12">
    <vue-jitsi-meet
      ref="jitsiRef"
      :domain="domain"
      :options="options"
    ></vue-jitsi-meet>
  </div>
</template>

<script>
import { JitsiMeet } from "@mycure/vue-jitsi-meet";
export default {
  name: "Jitsi",
  components: {
    VueJitsiMeet: JitsiMeet,
  },
  props: ["livestream"],
  data() {
    return {
      domain: "meet.jit.si",
    };
  },
  computed: {
    options() {
      return {
        roomName: this.livestream.jitsi.roomName,
        configOverwrite: {
          remoteVideoMenu: {
            disableKick: this.livestream.jitsi.disableKick,
          },
          startWithAudioMuted: this.livestream?.jitsi.startWithAudioMuted,
          prejoinPageEnabled: this.livestream?.jitsi.prejoinPageEnabled,
          enableNoAudioDetection: this.livestream?.jitsi.enableNoAudioDetection,
          enableNoisyMicDetection: this.livestream?.jitsi
            .enableNoisyMicDetection,
          disableShortcuts: this.livestream?.jitsi.disableShortcuts,
          disable1On1Mode: this.livestream?.jitsi.disable1On1Mode,
          toolbarButtons: this.livestream?.jitsi.toolbarButtons,
          startWithVideoMuted: this.livestream?.jitsi.startWithVideoMuted,
          hideDisplayName: this.livestream?.jitsi.hideDisplayName,
        },
        interfaceConfigOverwrite: {
          DISABLE_DOMINANT_SPEAKER_INDICATOR: this.livestream?.jitsi
            .disableDominantSpeakerIndicator,
          HIDE_INVITE_MORE_HEADER: this.livestream?.jitsi.hideInviteMoreHeader,
          DISABLE_PRIVATE_MESSAGES: this.livestream?.jitsi
            .disablePrivateMessages,
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: this.livestream?.jitsi
            .disableJoinLeaveNotifications,
        },
        userInfo: {
          email: this.currentUser?.email,
          displayName: `${this.currentUser?.first_name} ${this.currentUser?.last_name}`,
        },
      };
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
