<template>
  <div class="whereby-player-container col-md-12 col-lg-12">
    <iframe
      id="whereby-iframe"
      class="whereby-player"
      frameborder="0"
      :src="wherebyLink"
      allow="camera; microphone; fullscreen; speaker; display-capture"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "Whereby",
  props: ["code", "livestream"],
  async created() {
    await this.$store.dispatch("Whereby/fetchAll");
  },
  computed: {
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    wherebyLink() {
      let username = `${this.currentUser.first_name}`;
      let link = `${this.code}?embed&chat=${
          this.livestream.chatToggle ? "on" : "off"
        }&video=${this.livestream.videoToggle ? "on" : "off"}&audio=${
          this.livestream.audioToggle ? "on" : "off"
        }&screenshare=${this.livestream.screenshare ? "on" : "off"}&people=${
          this.livestream.people ? "on" : "off"
        }&leaveButton=${this.livestream.leaveButton ? "on" : "off"}&logo=${
          this.livestream.logo ? "on" : "off"
        }&background=${this.livestream.background ? "on" : "off"}&locking=${
          this.livestream.locking ? "on" : "off"
        }&participantCount=${
          this.livestream.participantCount ? "on" : "off"
        }&settingsButton=${
          this.livestream.settingsButton ? "on" : "off"
        }&moreButton=${this.livestream.moreButton ? "on" : "off"}&breakout=${
          this.livestream.breakout ? "on" : "off"
        }&timer=${this.livestream.timer ? "on" : "off"}&precallReview=${
          this.livestream.precallReview ? "on" : "off"
        }&personality=${
          this.livestream.personality ? "on" : "off"
        }&displayName=${
          username
        }`;
      
      if(this.currentUser.role == 'moderator' ||  this.currentUser.role == 'admin') {
        link += `&roomKey=${
          this.livestream.whereby.roomKey
        }`
      }
      console.log(link);
      return link;
    },
  },
};
</script>
<style>
.whereby-player-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
}
.whereby-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 75%;
}

@media only screen and (max-width: 999px) {
  .whereby-player-container {
    padding-top: 75%;
  }
}
</style>
