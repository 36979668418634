<template>
  <b-row>
    <b-col cols="12"> </b-col>
    <b-col v-for="(block, index) in blocks" :key="index" cols="12">
      <!-- <Schedule v-if="block.type.toLowerCase() == 'schedule' && block.active" /> -->
      <Video
        class="col-lg-12 mb-5"
        v-if="block.type.toLowerCase() == 'livestream' && block.active"
        :id="block.selectedLivestream[locale]"
      />
      <SocialWall
        v-if="block.type.toLowerCase() == 'social' && block.active"
        :social="block"
      />
      <Banner
        v-if="block.type.toLowerCase() == 'banner' && block.active"
        :banner="block"
      />
      <CardList
        v-if="block.type.toLowerCase() == 'items' && block.active"
        :cardlist="block"
      />
      <Carousel
        v-if="block.type.toLowerCase() == 'carousel' && block.active"
        :carousel="block"
      />
      <TempNextEvent v-if="block.type == 'nextEvent' && block.active" />
      <Form
          v-if="block.type.toLowerCase() == 'form' && block.active"
          :form="block"
      />

    </b-col>
  </b-row>
</template>
<script>
import Banner from "@/components/landing/Banner.vue";
import CardList from "@/components/landing/CardList.vue";
import Carousel from "@/components/landing/Carousel.vue";
import SocialWall from "@/components/landing/SocialWall.vue";
// import Schedule from "@/components/landing/Schedule.vue";
import Form from "@/components/landing/Form";
import Video from "@/components/video/Video";
import TempNextEvent from "@/components/landing/TempNextEvent.vue";

export default {
  name: "Items",
  props: ["blocks"],
  components: {
    Banner,
    CardList,
    Video,
    Carousel,
    SocialWall,
    // Schedule,
    TempNextEvent,
    Form,
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
