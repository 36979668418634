<template>
  <div class="embedded-chat" style="height: 100%; width: 100%;"></div>
</template>
<script>
export default {
  name: "vimeo",
  props: ["currentUser", "code", "roomCode"],
  computed: {
    userName() {
      return `${this.currentUser?.first_name} ${this.currentUser?.last_name}`;
    },
    profilePicture() {
      return this.currentUser?.profile_image_url;
    },
  },
  created() {
    window.embeddedChat.setUser({
      name: this.userName,
      profile_image: this.profilePicture,
    });
    window.embeddedChat.init(this.code);
    window.embeddedChat.changeRoom(this.roomCode)
  },
};
</script>
<style lang="scss">
.chat {
  height: 100%;
  min-height: 536.364px;
  min-width: 265.455px;
}
</style>
