<template>
    <iframe
      class="zoom-player"
      src="/zoom"
      frameborder=0
    ></iframe>
</template>
<script>
export default {
  name: "Zoom",
};
</script>
<style lang="scss">
// #zmmtg-root {
//   display: none;
// }
</style>