<template>
  <div class="list-group-item d-flex align-items-top" :class="{ current: isCurrent}">
    <div class="">
      <!-- Avatar -->
      <b-img :src="imageUrl" alt="..." class="schedule-img" />
    </div>
    <div class="">
      <!-- Title -->
      <b class="mb-1">
        <a class="card-text" :href="itemUrl">{{ title[locale] }}</a>
      </b>

      <!-- Time -->
      <p class="card-text small text-muted">
        <time datetime="2018-05-24">{{ startDate | moment("HH:mm")}} - {{ endDate | moment("HH:mm") }}</time>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item", "isCurrent"],
  computed: {
    itemUrl() {
      return this.item.room_url;
    },
    imageUrl() {
      return this.item.image_url;
    },
    title() {
      return this.item.title;
    },
    description() {
      return this.item.description;
    },
    startDate() {
      return this.item.start.substring(0, this.item.start.length - 3);
    },
    endDate() {
      return this.item.stop.substring(0, this.item.stop.length - 3);
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.schedule-img {
  width: 40px;
  margin-right: 10px;
}

.list-group-item {
  background-color: $color-primary;
  width:100%;
  padding-left: 15px;
  padding-right: 15px;

  p, a {
    color: $text-color-header;
  }
}

.current {
  background-color: $color-secondary;
  p, a {
    color: $text-color-title;
  }
}

</style>
