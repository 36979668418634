<template>
  <div
    class="video-player"
    :style="{ height: videoHeight }"
    v-if="livestreamAvailable"
  >
    <div class="video-player-container" ref="videoDiv">
      <div :class="videoClasses">
        <Vimeo
          class="video"
          :currentUser="currentUser"
          :code="livestream.code[locale]"
          :chatActive="chatActive"
          :isEvent="livestreamType == 'vimeoEvent' ? true : false"
          v-if="
            livestreamType === 'vimeoEvent' || livestreamType === 'vimeoPlayer'
          "
        />
        <Zoom class="video" v-if="livestreamType === 'zoom'" />
        <Youtube
          class="video"
          :chatActive="chatActive"
          :code="livestream.code[locale]"
          :livestream="livestream"
          v-if="livestreamType === 'youtube'"
        />
        <Whereby
          class="video"
          :chatActive="chatActive"
          :code="livestreamCode"
          :livestream="livestream"
          v-if="livestreamType === 'whereby'"
        />
        <Jitsi
          class="video"
          :chatActive="chatActive"
          :livestream="livestream"
          v-if="livestreamType === 'jitsi'"
        />

        <b-overlay no-wrap :show="!livestream.active">
          <template #overlay>
            <div class="text-center">
              <b-icon
                icon="stopwatch"
                font-scale="3"
                animation="cylon"
              ></b-icon>
              <p id="cancel-label">{{ livestream.inactiveText[locale] }}</p>
              <!-- <b-button
                ref="cancel"
                variant="outline-danger"
                size="sm"
                aria-describedby="cancel-label"
                @click="show = false"
              >
                Cancel
              </b-button> -->
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
<script>
import Zoom from "@/components/video/Zoom.vue";
import Youtube from "@/components/video/Youtube.vue";
import Vimeo from "@/components/video/Vimeo.vue";
import Whereby from "@/components/interactive-livestream/Whereby.vue";
import Jitsi from "@/components/interactive-livestream/Jitsi.vue";

export default {
  props: ["id"],
  name: "Home",
  components: {
    Vimeo,
    Zoom,
    Youtube,
    Whereby,
    Jitsi,
  },
  data() {
    return {
      livestream: {},
      isStuck: false,
      outerHeightVideo: 700,
    };
  },
  async created() {
    await this.fetchLivestream();
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    livestreamAvailable() {
      return this.livestream?.id;
    },
    settings() {
      return this.$store.state.Config.all;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    chatActive() {
      return this.livestream?.chat?.active;
    },
    livestreamType() {
      return this.livestream?.type[this.locale];
    },
    livestreamCode() {
      return this.livestream?.code[this.locale];
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    videoHeight() {
      if (this.isStuck) {
        return `${this.outerHeightVideo}px`;
      }
      return "auto";
    },
    videoClasses() {
      if (this.isStuck) {
        return "video-fv stuck";
      }
      return "video-fv";
    },
  },
  methods: {
    async fetchLivestream() {
      await this.$store.dispatch("Livestreams/fetchById", this.id);
      this.livestream = await this.$store.getters["Livestreams/livestream"];
    },
    handleScroll() {
      var windowScrollTop = window.scrollY;
      const videoHeight = this.outerHeightVideo * 0.75;
      const offsetVideoWrap =
        this.$refs.videoDiv.getBoundingClientRect().top + windowScrollTop;
      var videoBottom = videoHeight + offsetVideoWrap;
      console.log("bottom", videoBottom, windowScrollTop);

      if (windowScrollTop > videoBottom) {
        this.isStuck = true;
      } else {
        this.isStuck = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.video-player {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.video-player-container {
  padding-left: 0px !important;
  padding-right: 0px;
  padding-top: 56.25%;
  width: 100%;
  height: auto;

  .video {
    padding-left: 0px !important;
    padding-right: 0px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.stuck {
  position: fixed;
  bottom: 73px;
  right: 20px;
  transform: translateY(100%);
  width: 600px;
  height: 300px;
  animation: fade-in-up 0.25s ease forwards;
  z-index: 5000;
}
</style>
